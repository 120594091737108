import Vue from 'vue'
import App from './App.vue'
import router from './router'
//import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vuetify from './plugins/vuetify'
import ElementUI from 'element-ui'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/ja'
import { Nyukai } from './NyukaiInfo'
import { NyukaiReservation } from './NyukaiReservation'
import { NyukaiCustomer } from './NyukaiCustomer'
import { NyukaiConfirm } from './NyukaiConfirm'
import { NyukaiPayment } from './NyukaiPayment'
//import firebase from 'firebase'
//import vuemoment from 'vue-moment'

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(ElementUI, { locale })

Vue.use(Vuex)
Vue.use(createPersistedState)

Vue.use(require('vue-moment'));


const nyukaiObject = new Nyukai();
const nyukaiReservation = new NyukaiReservation();
const nyukaiCustomer = new NyukaiCustomer();
const nyukaiConfirm = new NyukaiConfirm();
const nyukaiPayment = new NyukaiPayment();




//フロントURL
//const frontendUrl = "https://agreeable-glacier-02c5b1d00.azurestaticapps.net"; //テスト
//const frontendUrl = "https://www.renaiss.ga"; //旧 本番
//const frontendUrl = "http://localhost:8080"; //テスト
const frontendUrl = "https://www.renaiss.shop"; //新 本番

//サーバサイドURL
//const backendUrl = "https://renaiss-webapi-test.azurewebsites.net"; //テスト
const backendUrl = "https://renaisswebapi.azurewebsites.net"; //本番


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
/*
var firebaseConfig = {
    apiKey: "AIzaSyAfHAxotTcPemn3IcUR46Q-fDFT16AePic",
    authDomain: "renaiss-39f16.firebaseapp.com",
    projectId: "renaiss-39f16",
    storageBucket: "renaiss-39f16.appspot.com",
    messagingSenderId: "528569764686",
    appId: "1:528569764686:web:15d0c20cbc57c8a64bec1f",
    measurementId: "G-KQT5VKNYLD"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
*/
const store = new Vuex.Store({

    state: {
        projectStarturl: frontendUrl + "/nyukai",//���e�X�g���@�{�Ԋ���"https://www.renaiss.ga/nyukai",
        confirmUrl: frontendUrl + "/confirm",
        apiUrl: backendUrl,
        nyukai: nyukaiObject,
        nyukaiReservation: nyukaiReservation,
        isNyukaiReservationFormed: false,
        nyukaiCustomer: nyukaiCustomer,
        isNyukaiCustomerFormed: false,
        nyukaiConfirm: nyukaiConfirm,
        isNyukaiConfirmFormed: false,
        nyukaiPayment: nyukaiPayment,
        isNyukaiPaymentFormed: false,
        nyukaiObjectID: "",

        //�\�����e�t�H�[�����ŏ��ɊJ�������ǂ����𔻒肷��v���p�e�B
        isInit: true,

        //�̌��I��and����I��
        isSelectedTrial: false,
        isSelectedJoin: false,
        //�T����I�����Ă��邩�ǂ���
        isSelectedTwiceAWeek: false,
        //�X��
        stores: [],
        selectedStore: null,
        selectedStoreIndex: null,
        //�̌����t
        trials: [],
        selectedTrial: null,
        selectedTrialIndex: null,
        //���b�X������
        weeks: [],
        selectedWeek1: null,
        selectedWeek2: null,
        selectedWeek1Index: null,
        selectedWeek2Index: null,

        //���񗈓X��
        visits: [],
        selectedVisit: null,
        selectedVisitIndex: null,

        //�o�X���͋�
        buses: [],
        selectedBus: null,
        selectedBusIndex: null,

        //����
        sizes: [],
        selectedSize: null,
        selectedSizeIndex: null,

        totalprice: 0,//���v���z
        admissionfee: {},//�����
        annualfee: {},//�N���
        monthlyfee: {},//�����
        swimsuit: {},//����
        cap: {},//�L���b�v
        bag: {},//�o�b�O
        trial: {},//�̌����

        //�m�F��ʂ�api�Ƀ|�X�g�������ǂ���
        isPostData: false,

        //�\�����݂������������ǂ���
        isComplete: false,

        coupon: {
            couponID: null,
            discountFlag: false,
            freeBagFlag: false,    
            freeCapFlag: false,    
            freeSuitFlag: false,
        },   

        discountValue: 0,

        //regiDiv: null

        //APIと種別を同期させる
        getItemKind : {
            entoryFee: 1,
            annualFee: 2,
            //月会費1ヶ月目
            monthlyfee1: 3,
            //月会費2ヶ月目
            monthlyfee2: 4,
            swimsuit: 5,
            cap: 6,
            bag: 7,
            trial: 8,
            //バス協力金1ヶ月目
            bus1: 9,
            //バス協力金2ヶ月目
            bus2: 10,
        },
        GetItemByKindParamObject : class GetItemByKindParamObject {
            constructor({ kind, itemCD, storeID = null, isFree = null, weeklyLessonCount = null, busCount = null }) {
                this.kind = kind;
                this.itemCD = itemCD;
                this.weeklyLessonCount = weeklyLessonCount;
                this.busCount = busCount;
                this.storeID = storeID;
                this.isFree = isFree;
            }
            toString() {
                return JSON.stringify(this);
            }
        }

    },
    mutations: {
        resetNyukai: function (state) {
            state.nyukai = new Nyukai();
        },
        updateNyukai: function (state, nyukai) {
            state.nyukai = new Nyukai().update(nyukai);
        },
        updateNyuakiEachParam: function (state, nyukai) {
            state.nyukai[nyukai.name] = nyukai.value;
        },
        resetNyukaiObject: function (state) {
            state.nyukaiObjectID = "";
        },
        updateNyukaiObject: function (state, id) {
            state.nyukaiObjectID = id;
        },
        updateNyukaiReservation: function (state, nyukaiReservation) {
            //������object�ɉ��̃J�������X�V���邩���ڂ���
            var columnName = nyukaiReservation.kind;
            state.nyukaiReservation[columnName] = nyukaiReservation[columnName];

        },
        resetNyukaiReservation: function (state) {
            state.nyukaiReservation = new NyukaiReservation();
        },
        updateNyukaiCustomer: function (state, nyukaiCustomer) {
            //������object�ɉ��̃J�������X�V���邩���ڂ���
            var columnName = nyukaiCustomer.kind;
            state.nyukaiCustomer[columnName] = nyukaiCustomer[columnName];

        },
        resetNyukaiCustomer: function (state) {
            state.nyukaiCustomer = new NyukaiCustomer();
        },
        updateNyukaiConfirm: function (state, nyukaiConfirm) {
            //������object�ɉ��̃J�������X�V���邩���ڂ���
            var columnName = nyukaiConfirm.kind;
            state.nyukaiConfirm[columnName] = nyukaiConfirm[columnName];

        },
        resetNyukaiConfirm: function (state) {
            state.nyukaiConfirm = new NyukaiConfirm();
        },
        updateNyukaiPayment: function (state, nyukaiPayment) {
            //������object�ɉ��̃J�������X�V���邩���ڂ���
            var columnName = nyukaiPayment.kind;
            state.nyukaiPayment[columnName] = nyukaiPayment[columnName];

        },
        resetNyukaiPayment: function (state) {
            state.nyukaiPayment = new NyukaiPayment();
        },

        updateIsFormed: function (state, result) {
            state[result.name] = result.result;
        },
        updateIsInit: function (state, result) {
            state.isInit = result;
        },
        updateIsPostData: function (state, result) {
            state.isPostData = result;
        },
        updateIsComplete: function (state, result) {
            state.isComplete = result;
        },
        //v-if�Ŏg��bool�l�̍X�V�Bname�Ŗ��O���w��,result��bool�l
        updateShowBool: function (state, resultObject) {

            state[resultObject.name] = resultObject.result;

        },
        updateStores: function (state, stores) {
            state.stores = stores
            return "��";
        },
        updateSelectedStores: function (state, storeID) {
            state.selectedStoreIndex = "";
            state.selectedStore = null;

            for (var i = 0; i < state.stores.length; i++) {
                var store = state.stores[i];
                if (store.storeID == storeID) state.selectedStoreIndex = i; state.selectedStore = store;
            }
        },
        updateTrials: function (state, trials) {
            state.trials = trials
        },
        updateSelectedTrials: function (state, stockDataID) {
            state.selectedTrialIndex = "";
            state.selectedTrial = null;

            for (var i = 0; i < state.trials.length; i++) {
                var trial = state.trials[i];
                if (trial.stockDataID == stockDataID) state.selectedTrialIndex = i; state.selectedTrial = trial;
            }
        },
        updateWeeks: function (state, weeks) {
            state.weeks = weeks
        },
        updateSelectedWeeks1: function (state, ID) {
            state.selectedWeek1Index = "";
            state.selectedWeek1 = null;
            for (var i = 0; i < state.weeks.length; i++) {
                var week = state.weeks[i];
                if (week.ID == ID) state.selectedWeek1Index = i; state.selectedWeek1 = week;
            }
        },
        updateSelectedWeeks2: function (state, ID) {
            state.selectedWeek2Index = "";
            state.selectedWeek2 = null;

            for (var i = 0; i < state.weeks.length; i++) {
                var week = state.weeks[i];
                if (week.ID == ID) state.selectedWeek2Index = i; state.selectedWeek2 = week;
            }

        },
        updateVisits: function (state, visits) {
            state.visits = visits
        },
        updateSelectedVisits: function (state, ID) {
            state.selectedVisitIndex = "";
            state.selectedVisit = null;
            for (var i = 0; i < state.visits.length; i++) {
                var visit = state.visits[i];
                if (visit.ID == ID) state.selectedVisitIndex = i; state.selectedVisit = visit;
            }
            console.log(state.visits, state.selectedVisitIndex);

        },
        updateBuses: function (state, buses) {
            state.buses = buses
        },
        updateSelectedBuses: function (state, itemCD) {
            state.selectedBusIndex = "";
            state.selectedBus = null;
            for (var i = 0; i < state.buses.length; i++) {
                var bus = state.buses[i];
                if (bus.itemCD == itemCD) state.selectedBusIndex = i; state.selectedBus = bus;
            }
        },
        updateSizes: function (state, sizes) {
            state.sizes = sizes
        },
        updateSelectedSizes: function (state, itemCD) {
            state.selectedSizeIndex = "";
            state.selectedSize = null;
            for (var i = 0; i < state.sizes.length; i++) {
                var size = state.sizes[i];
                if (size.itemCD == itemCD) state.selectedSizeIndex = i; state.selectedSize = size;
            }
        },


        updatePaymentObject: function (state, result) {
            state[result.name] = result.result;
        },
        //���v���z�v�Z
        updateTotalPrice: function (state) {
            state.totalprice = 0;

            if (state.isSelectedJoin) {
                state.totalprice += state.admissionfee.itemPrice;
                state.totalprice += state.annualfee.itemPrice;
                state.totalprice += state.monthlyfee.itemPrice * 2;
                state.totalprice += state.swimsuit.itemPrice;
                state.totalprice += state.cap.itemPrice;
                state.totalprice += state.bag.itemPrice;
                state.totalprice += state.buses[state.selectedBusIndex].itemPrice*2;
            }
            if (state.isSelectedTrial) {
                state.totalprice += state.trial.itemPrice;
            }

        },

        vuexSetCoupon : function(state, payload) {
            state.coupon = payload;
        },    
        vuexSetDiscountValue : function(state, payload) {
            state.discountValue = payload;
        },
        vuexSetRegiDiv : function(state, payload) {
            state.nyukai.regiDiv = payload;
        }    




    },
    plugins: [createPersistedState({ storage: window.sessionStorage })]
});




new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')

